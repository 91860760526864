import { useParams } from 'react-router-dom';
import AppSidebar from './AppSidebar';
import { Navbar } from './Components/Catalyst/navbar';
import { SidebarLayout } from './Components/Catalyst/sidebar-layout';
import { useEffect, useState } from 'react';
import { homeService } from './ApiClients';
import {
    HomeCreateOrUpdateSchema,
    HomeSchema,
    OwnerSchema,
} from './hoa-api-client';
import { Heading, Subheading } from './Components/Catalyst/heading';
import { Button } from './Components/Catalyst/button';
import { Divider } from './Components/Catalyst/divider';
import {
    Description,
    ErrorMessage,
    Field,
    FieldGroup,
    Fieldset,
    Label,
    Legend,
} from './Components/Catalyst/fieldset';
import { Input } from './Components/Catalyst/input';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogTitle,
} from './Components/Catalyst/dialog';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownMenu,
} from './Components/Catalyst/dropdown';

interface ContactInfoItem {
    name: string;
    render: (value: any) => React.ReactNode;
}

interface TouchedFields {
    [key: string]: boolean;
}

interface FormErrors {
    [key: string]: string;
}

const HomeDetailPage: React.FC = () => {
    const { hoaId, homeId } = useParams<{ hoaId: string; homeId: string }>();
    const [loading, setLoading] = useState(true);
    const [home, setHome] = useState<HomeSchema | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedHome, setEditedHome] =
        useState<HomeCreateOrUpdateSchema | null>(null);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
    const [isSaving, setIsSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [currentOwner, setCurrentOwner] = useState<OwnerSchema>({
        first_name: '',
        last_name: '',
    });

    const propertyTypes = [
        'Single Family',
        'Townhouse',
        'Condo',
        'Apartment',
        'Other',
    ];
    const statusOptions = [
        'Owner Occupied',
        'Renter Occupied',
        'Vacant',
        'For Sale',
        'For Rent',
        'Unknown',
    ];

    useEffect(() => {
        const fetchHoa = async () => {
            try {
                setLoading(true);
                const response = await homeService.getHomeByHoaAndId({
                    hoaId: Number(hoaId),
                    homeId: Number(homeId),
                });
                setHome(response.data);
                setError(null);
            } catch (err) {
                console.error('Error fetching home:', err);
                setError(
                    `Failed to fetch Home details for home id ${homeId}. Please try again later.`
                );
            } finally {
                setLoading(false);
            }
        };

        fetchHoa();
    }, [hoaId, homeId]);

    const handleEdit = () => {
        if (home) {
            const { id, date_created, date_updated, ...editableFields } = home;
            setEditedHome(editableFields as HomeCreateOrUpdateSchema);
            setIsEditing(true);
            setTouchedFields({});
            setFormErrors({});
        }
    };

    const handleSave = async () => {
        if (!editedHome || !home) return;

        setIsSaving(true);
        setError(null);
        setSuccessMessage(null);

        // Validate all fields before submitting
        const errors: FormErrors = {};
        Object.keys(editedHome).forEach((key) => {
            const error = validateField(key, (editedHome as any)[key]);
            if (error) {
                errors[key] = error;
            }
        });

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setIsSaving(false);
            return;
        }

        try {
            await homeService.updateHome({
                hoaId: Number(hoaId),
                homeId: home.id,
                homeCreateOrUpdateSchema: editedHome,
            });
            setHome({ ...home, ...editedHome });
            setIsEditing(false);
            setSuccessMessage('Home details updated successfully!');
            setTimeout(() => setSuccessMessage(null), 5000);
        } catch (err) {
            console.error('Error updating Home:', err);
            setError('Failed to update Home. Please try again later.');
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedHome(null);
        setFormErrors({});
        setTouchedFields({});
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!editedHome) return;
        const { name, value } = e.target;
        setEditedHome({ ...editedHome, [name]: value });
        setTouchedFields({ ...touchedFields, [name]: true });
        const error = validateField(name, value);
        setFormErrors((prev) => ({ ...prev, [name]: error }));
    };

    const validateField = (name: string, value: any): string => {
        switch (name) {
            case 'street':
            case 'city':
            case 'state':
            case 'zip':
                return !value
                    ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required`
                    : '';
            case 'email':
                return !value
                    ? 'Email is required'
                    : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
                      ? 'Invalid email format'
                      : '';
            case 'phone':
                if (!value) {
                    return 'Phone is required';
                }
                const digitsOnly = value.replace(/\D/g, '');
                return digitsOnly.length < 10 || digitsOnly.length > 11
                    ? 'Phone must be 10 or 11 digits'
                    : '';
            case 'owners':
                return !value || value.length === 0
                    ? 'At least one owner is required'
                    : '';
            case 'property_type':
            case 'status':
                return !value ||
                    value === 'Select Type' ||
                    value === 'Select Status'
                    ? `${name.replace('_', ' ')} is required`
                    : '';
            default:
                return '';
        }
    };

    const handleDialogClose = () => {
        setEditedHome(null);
        setIsEditing(false);
        setFormErrors({});
        setTouchedFields({});
    };

    const handleOwnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCurrentOwner((prev) => ({ ...prev, [name]: value }));
    };

    const addOwner = () => {
        if (currentOwner.first_name && currentOwner.last_name && editedHome) {
            setEditedHome((prev) => {
                if (!prev) return prev;
                const updatedOwners = [...prev.owners, currentOwner];
                const error = validateField('owners', updatedOwners);
                setFormErrors((prevErrors) => {
                    if (error) {
                        return { ...prevErrors, owners: error };
                    } else {
                        const { owners, ...rest } = prevErrors;
                        return rest;
                    }
                });
                return { ...prev, owners: updatedOwners };
            });
            setCurrentOwner({ first_name: '', last_name: '' });
        }
    };

    const removeOwner = (index: number) => {
        if (editedHome) {
            setEditedHome((prev) => {
                if (!prev) return prev;
                const updatedOwners = prev.owners.filter((_, i) => i !== index);
                const error = validateField('owners', updatedOwners);
                setFormErrors((prevErrors) => {
                    if (error) {
                        return { ...prevErrors, owners: error };
                    } else {
                        const { owners, ...rest } = prevErrors;
                        return rest;
                    }
                });
                return { ...prev, owners: updatedOwners };
            });
        }
    };

    if (loading) {
        return <div>Loading HOA details...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!home) {
        return <div>No Home found with the given ID.</div>;
    }

    const contactInfo: ContactInfoItem[] = [
        {
            name: 'Owners',
            render: (owners: OwnerSchema[]) => (
                <div className="break-words">
                    {owners.map((owner, index) => (
                        <div key={index} className="mb-1">
                            {`${owner.first_name} ${owner.last_name}`}
                        </div>
                    ))}
                </div>
            ),
        },
        {
            name: 'Email',
            render: (email: string) => (
                <div className="break-words">{email || 'N/A'}</div>
            ),
        },
        {
            name: 'Phone',
            render: (phone: string) => <div>{phone || 'N/A'}</div>,
        },
    ];

    const fullAddress = home.street2
        ? `${home.street}, ${home.street2}`
        : home.street;
    const cityStateZip = `${home.city}, ${home.state} ${home.zip}`;

    return (
        <SidebarLayout
            sidebar={<AppSidebar currentPage="hoa_detail" />}
            navbar={<Navbar>{/* Your navbar content */}</Navbar>}
        >
            <div className="flex justify-between items-center">
                <Heading level={2}>{fullAddress}</Heading>
                <div>
                    <Button onClick={handleEdit} className="mr-2">
                        Edit Home Details
                    </Button>
                </div>
            </div>
            <Subheading>{cityStateZip}</Subheading>

            <Divider className="mt-4 mb-6" />

            {successMessage && (
                <div className="mt-4 p-4 bg-green-100 text-green-700 rounded">
                    {successMessage}
                </div>
            )}

            <div>
                <Heading
                    level={4}
                    className="text-base font-semibold leading-6 text-zinc-900 dark:text-white mb-4"
                >
                    Contact Information
                </Heading>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                    {contactInfo.map((item) => (
                        <div
                            key={item.name}
                            className="overflow-hidden rounded-lg bg-white dark:bg-zinc-800 px-4 py-5 shadow sm:p-6 transition-colors duration-200"
                        >
                            <dt className="truncate text-sm font-medium text-zinc-500 dark:text-zinc-400">
                                {item.name}
                            </dt>
                            <dd className="mt-1 text-lg font-semibold tracking-tight text-zinc-900 dark:text-white">
                                {item.name === 'Owners'
                                    ? item.render(home.owners)
                                    : item.name === 'Email'
                                      ? item.render(home.email)
                                      : item.render(home.phone)}
                            </dd>
                        </div>
                    ))}
                </dl>
            </div>

            <Divider className="mt-4 mb-6" />

            <Dialog open={isEditing} onClose={handleDialogClose}>
                <DialogTitle>Edit Home</DialogTitle>
                <DialogBody>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label>Owners</Label>
                                <Description>
                                    at least one is required
                                </Description>
                                <div className="flex space-x-2 mb-2">
                                    <Input
                                        name="first_name"
                                        value={currentOwner.first_name}
                                        onChange={handleOwnerChange}
                                        placeholder="First Name"
                                    />
                                    <Input
                                        name="last_name"
                                        value={currentOwner.last_name}
                                        onChange={handleOwnerChange}
                                        placeholder="Last Name"
                                    />
                                    <Button
                                        outline
                                        onClick={addOwner}
                                        type="button"
                                    >
                                        Add
                                    </Button>
                                </div>
                                {editedHome?.owners.map((owner, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center space-x-2 mb-2"
                                    >
                                        <Label>
                                            {owner.first_name} {owner.last_name}
                                        </Label>
                                        <Button
                                            onClick={() => removeOwner(index)}
                                            type="button"
                                            color="red"
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                ))}
                                {touchedFields.owners && formErrors.owners && (
                                    <ErrorMessage>
                                        {formErrors.owners}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="street">Street</Label>
                                <Description>required</Description>
                                <Input
                                    id="street"
                                    name="street"
                                    value={editedHome?.street || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter street address"
                                    required
                                    data-invalid={
                                        touchedFields.street &&
                                        formErrors.street
                                    }
                                />
                                {touchedFields.street && formErrors.street && (
                                    <ErrorMessage>
                                        {formErrors.street}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="street2">Street 2</Label>
                                <Input
                                    id="street2"
                                    name="street2"
                                    value={editedHome?.street2 || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter additional street information (optional)"
                                />
                            </Field>
                            <Field>
                                <Label htmlFor="city">City</Label>
                                <Description>required</Description>
                                <Input
                                    id="city"
                                    name="city"
                                    value={editedHome?.city || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter city"
                                    required
                                    data-invalid={
                                        touchedFields.city && formErrors.city
                                    }
                                />
                                {touchedFields.city && formErrors.city && (
                                    <ErrorMessage>
                                        {formErrors.city}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="state">State</Label>
                                <Description>required</Description>
                                <Input
                                    id="state"
                                    name="state"
                                    value={editedHome?.state || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter state"
                                    required
                                    data-invalid={
                                        touchedFields.state && formErrors.state
                                    }
                                />
                                {touchedFields.state && formErrors.state && (
                                    <ErrorMessage>
                                        {formErrors.state}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="zip">ZIP Code</Label>
                                <Description>required</Description>
                                <Input
                                    id="zip"
                                    name="zip"
                                    value={editedHome?.zip || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter ZIP code"
                                    required
                                    data-invalid={
                                        touchedFields.zip && formErrors.zip
                                    }
                                />
                                {touchedFields.zip && formErrors.zip && (
                                    <ErrorMessage>
                                        {formErrors.zip}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="email">Email</Label>
                                <Description>required</Description>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={editedHome?.email || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter email"
                                    data-invalid={
                                        touchedFields.email && formErrors.email
                                    }
                                />
                                {touchedFields.email && formErrors.email && (
                                    <ErrorMessage>
                                        {formErrors.email}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="phone">Phone</Label>
                                <Description>required</Description>
                                <Input
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    value={editedHome?.phone || ''}
                                    onChange={handleInputChange}
                                    placeholder="Enter phone"
                                    data-invalid={
                                        touchedFields.phone && formErrors.phone
                                    }
                                />
                                {touchedFields.phone && formErrors.phone && (
                                    <ErrorMessage>
                                        {formErrors.phone}
                                    </ErrorMessage>
                                )}
                            </Field>
                            <Field>
                                <Label htmlFor="property_type">
                                    Property Type
                                </Label>
                                <Description>required</Description>
                                <Dropdown>
                                    <DropdownButton outline>
                                        {editedHome?.property_type ||
                                            'Select Property Type'}
                                        <ChevronDownIcon className="h-5 w-5 ml-2" />
                                    </DropdownButton>
                                    <DropdownMenu>
                                        {propertyTypes.map((type) => (
                                            <DropdownItem
                                                key={type}
                                                onClick={() =>
                                                    handleInputChange({
                                                        target: {
                                                            name: 'property_type',
                                                            value: type,
                                                        },
                                                    } as any)
                                                }
                                            >
                                                {type}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                {touchedFields.property_type &&
                                    formErrors.property_type && (
                                        <ErrorMessage>
                                            {formErrors.property_type}
                                        </ErrorMessage>
                                    )}
                            </Field>

                            <Field>
                                <Label htmlFor="status">Status</Label>
                                <Description>required</Description>
                                <Dropdown>
                                    <DropdownButton outline>
                                        {editedHome?.status || 'Select Status'}
                                        <ChevronDownIcon className="h-5 w-5 ml-2" />
                                    </DropdownButton>
                                    <DropdownMenu>
                                        {statusOptions.map((status) => (
                                            <DropdownItem
                                                key={status}
                                                onClick={() =>
                                                    handleInputChange({
                                                        target: {
                                                            name: 'status',
                                                            value: status,
                                                        },
                                                    } as any)
                                                }
                                            >
                                                {status}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                {touchedFields.status && formErrors.status && (
                                    <ErrorMessage>
                                        {formErrors.status}
                                    </ErrorMessage>
                                )}
                            </Field>
                        </FieldGroup>
                    </Fieldset>
                </DialogBody>
                <DialogActions>
                    <Button
                        onClick={handleCancel}
                        color="zinc"
                        disabled={isSaving}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="blue"
                        disabled={isSaving}
                    >
                        {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </SidebarLayout>
    );
};

export default HomeDetailPage;
